import './App.css';
import Navbar from "./Routes/Navbar.jsx"
import AllRoutes from "./Routes/AllRoutes.jsx"
import { Center } from '@chakra-ui/react';

function App() {
  return (
    <div>
      <Navbar />
      <Center>
      <AllRoutes />
      </Center>
    </div>
  );
}

export default App;
