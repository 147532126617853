import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Image } from '@chakra-ui/react'

const getData=async(x)=>{
  let data= await axios.get("https://elated-pea-coat-bat.cyclic.app/profile",{
    headers:{
      "Accept":"application/json",
      "authorization":x
    }
  })
  return data
}

const Profile = () => {
  const Navigate=useNavigate()
  const [data, setdata] = useState([])
  const [edit, setedit] = useState(false)
  const [name, setname] = useState("")
  const [author,setauthor]=useState("")
  let token=localStorage.getItem("token")
  token=JSON.parse(token)
  
  useEffect(()=>{
    getData(token).then((res)=>setdata(res.data))
  },[])
    
  if(data==="Please Login First."){
    alert("Please Login First.")
    return(
      Navigate("/login")
    )
  }
  if(data.length===0){
    return(
      <center>
        <img src="https://i.pinimg.com/originals/7e/2a/eb/7e2aeb1567e91bfc2404cecca6aceecd.gif"/>
      </center>
    )
  }
  const handleEdit=async(e)=>{
    setedit(!edit)
    }
    const handleUpdate=async(e,data)=>{
        return await axios.patch(`https://elated-pea-coat-bat.cyclic.app/profile/modify/${e}`,data,{
             headers:{
                 "Accept":"application/json",
                 authorization:token
             }
         })
     }
     const handleChange=async(f,e)=>{
        f.preventDefault()
        let data;
        if(name==="" && author===""){
           return alert("Please enter at least any one Credential to update.")
        }else if(name!=="" && author!==""){
            data={name,bio:author}
        }else if(author!==""){
            data={bio:author}
        }else{
            data={name}
        }
        if(data){
            await handleUpdate(e,data)
            alert("Updated Successfully.")
            window.location.reload()
        }
    }

  return (
    <div>
      <h1 style={{textAlign:"center"}}>Profile</h1>
        <div key={data[0]._id}>
        <div >
            <Image src={data[0].picture} alt={data[0].name}/>
            <h1>Name:-{data[0].name}</h1>
            <h1>Phone:- {data[0].phone}</h1>
            <h1>Bio:- {data[0].bio}</h1>
            <button style={{height:"20px"}} onClick={()=>handleEdit(data[0]._id)}>{edit?"Close":"Edit"}</button>
        </div>
            <form style={{display:edit?"":"none",margin:"auto",backgroundColor:"#050b4f",border:"1px solid white",padding:"40px"}} onSubmit={(e)=>handleChange(e,data[0]._id)}>
              
              <input style={{width:"100%",marginTop:"20px"}} type="text" placeholder='New Name' value={name} onChange={(e)=>setname(e.target.value)}/>
              <br />
              <input style={{width:"100%",marginTop:"20px"}} type="text" placeholder='New Bio' value={author} onChange={(e)=>setauthor(e.target.value)}/>
              <input type="submit" placeholder='SUBMIT' />
              
            </form>
            <hr />
      </div>
    </div>
  )
}

export default Profile