import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddProfile from './AddProfile'
import Login from './Login'
import Profile from './Profile'
import Register from './Register'

const AllRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<h1>Hello</h1>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register />}/>
        <Route path='/profile' element={<Profile />}/>
        <Route path='/addProfile' element={<AddProfile />}/>
    </Routes>
  )
}

export default AllRoutes