import React from 'react'
import {
    Box,
    Button,
    HStack
} from "@chakra-ui/react"
import{ Navigate, NavLink, useNavigate} from "react-router-dom"

const Navbar = () => {
    const navigate=useNavigate()
    let tok=localStorage.getItem("token") || ""

    const handleLogout=()=>{
        localStorage.removeItem("token")
        alert("Successfukky Logged out.")
        navigate("/")
        window.location.reload()
    }
  return (
    <HStack>
        {tok.length===0?<Box>
        <NavLink to="/login">
                <Button>
                Login
                </Button>
        </NavLink>
            </Box>:""}
        {tok.length===0?<Box>
        <NavLink to="/register">
                <Button>
                Register
                </Button>
        </NavLink>
            </Box>:""}

        
            {tok.length!==0?
            <NavLink to="/addProfile">

            <Box>
                <Button>
                Add Profile
                </Button>
            </Box>
            </NavLink>
            :""}

{tok.length!==0?
            <NavLink to="/profile">

            <Box>
                <Button>
                 Profile
                </Button>
            </Box>
            </NavLink>
            :""}

            {tok.length!==0?
            <Box>
                <Button onClick={()=>handleLogout()}>
                Logout
                </Button>
            </Box>:""}
    </HStack>
  )
}

export default Navbar