import { Box, Input, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axios from "axios"
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const [email, setemail] = useState("")
    const [pwd, setpwd] = useState("")
    const navigate=useNavigate()
    
    let tok=localStorage.getItem("token") || ""
    useEffect(()=>{
            if(tok.length!==0){
                 navigate("/profile")
            }
        },[])
    const handleSubmit=(e)=>{
        e.preventDefault()
        let data={email,pwd}
        axios.patch("https://elated-pea-coat-bat.cyclic.app/login/loginUser",data).then((res)=>{
            console.log(res)
            if(res.data==="Wrong Credentials"){
                alert("Wrong Credentials.")
                alert("No such user exist")
                navigate("/register")
            }else if(res.data==="No such user exist"){
                alert("No such user exist")
                navigate("/register")
            }else if(res.data.data.length!==0){
                localStorage.setItem("token",JSON.stringify(res.data.token))
                alert("SuccessFully Logged in.")
                window.location.reload()
            }
        })
    }

  return (
    <Box>
        <VStack>
            <form onSubmit={(e)=>handleSubmit(e)}>
                <Input required onChange={(e)=>setemail(e.target.value)} type="email" placeholder='Email'/>
                <Input required onChange={(e)=>setpwd(e.target.value)} type="password" placeholder='Password'/>
                <Input type="submit" placeholder='SUBMIT'/>
            </form>
        </VStack>
    </Box>
  )
}

export default Login