import { Box, Input, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axios from "axios"
import { useNavigate } from 'react-router-dom'

const Register = () => {
    const [email, setemail] = useState("")
    const [pwd, setpwd] = useState("")
    const navigate=useNavigate()

    let tok=localStorage.getItem("token") || ""
    useEffect(()=>{
        if(tok.length!==0){
             navigate("/profile")
        }
    },[])
    const handleSubmit=(e)=>{
        e.preventDefault()
        let data={email,pwd}
        axios.post("https://elated-pea-coat-bat.cyclic.app/login/adddetails",data).then((res)=>{
            console.log(res)
            if(res.data==="Already Exist"){
                alert("Already Exist")
            }else if(res.data=="succesfully added"){
                alert("Succesfully Registered.")
            }
        })
    }

  return (
    <Box>
        <VStack>
            <form onSubmit={(e)=>handleSubmit(e)}>
                <Input required onChange={(e)=>setemail(e.target.value)} type="email" placeholder='Email'/>
                <Input required onChange={(e)=>setpwd(e.target.value)} type="password" placeholder='Password'/>
                <Input type="submit" placeholder='SUBMIT'/>
            </form>
        </VStack>
    </Box>
  )
}

export default Register