import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AddProfile = () => {
  const Navigate=useNavigate()
  const [book, setbook] = useState("")
  const [author, setauthor] = useState("")
  const [pic, setpic] = useState("")
  const [num, setnum] = useState("")
  let token=localStorage.getItem("token")
  token=JSON.parse(token)

  const handleSubmit=async(e)=>{
    e.preventDefault()
    if(book!=="" && author!==""){
      let data={name:book,bio:author,phone:num,picture:pic}
      await axios.post("https://elated-pea-coat-bat.cyclic.app/profile/create",data,{
        headers:{
          "Accept":"application/json",
          "authorization":token
        }
      })
      alert("Added Successfully.")
      book=""
      author=""
    }else{
      alert("Fill All credentials.")
    }
  }

  if(!token){
    alert("To add Profile You first need to login.")
    return Navigate("/login")
  }
  return (
    <div>
      <form onSubmit={(e)=>handleSubmit(e)} style={{margin:"auto",backgroundColor:"#050b4f",border:"1px solid white",padding:"40px",color:"green"}}>
        <label>
         Name:-
        </label>
        <input style={{width:"100%"}} type="text" required placeholder='Name' value={book} onChange={(e)=>setbook(e.target.value)} />
        <br />
        <label>
          Bio:-
        </label>
        <input style={{width:"100%"}} type="text" required placeholder='Bio' value={author}  onChange={(e)=>setauthor(e.target.value)}/>
        <br />
        <label>
          Picture:-
        </label>
        <input style={{width:"100%"}} type="text" required placeholder='Picture' value={pic}  onChange={(e)=>setpic(e.target.value)}/>
        <br />
        <label>
          Phone:-
        </label>
        <input style={{width:"100%"}} required type="number" placeholder='Phone' value={num}  onChange={(e)=>setnum(e.target.value)}/>
        <br />
        <input style={{width:"100%",marginTop:"40px"}} type="submit" placeholder='SUBMIT' />
      </form>
    </div>
  )
}

export default AddProfile